.input-range,
.input-range .noUi-handle {
  box-shadow: none;
}

.input-range .noUi-handle::before,
.input-range .noUi-handle::after {
  display: none;
}

.input-range__track {
  border: none;
  height: 16px;
  background-color: var(--color_bg--primary);
  border-radius: var(--radius-half);
}

.input-range .input-range__track-1 {
  background-color: var(--color--accent);
  border-radius: var(--radius-half);
  transform: scaleX(0.99);
}

.input-range .noUi-connects {
  border-radius: 0;
  overflow: visible;
}

.input-range .input-range__thumb {
  height: 32px;
  width: 32px;
  top: -8px;
  right: -16px;

  height: 32px;
  width: 32px;
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
  background-color: var(--color_bg--primary-light);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.input-range__thumb-1 {
  //transform: translateX(50%);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00016 4.66797L9.3335 8.0013L6.00016 11.3346' stroke='%232A5AFC' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.input-range__thumb-0 {
  //transform: translateX(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.33333 4.66797L6 8.0013L9.33333 11.3346' stroke='%232A5AFC' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
