.list li {
  display: flex;
  align-items: baseline;

  counter-increment: item--numbered;
}

.list li::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: var(--radius-half);
  background-color: var(--color--primary-inverse);
  margin-right: 8px;
  flex-shrink: 0;
}

.list_color--accent li::before {
  background-color: var(--color--accent);
}

.list_view--done li::before {
  width: 32px;
  height: 32px;
  border-radius: var(--radius-half);
  background-color: var(--color_bg--primary);
  margin-right: 8px;
  background-position: center;
  background-size: 11px 13px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1' stroke='%23E84C4C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.list_view--numbered {
  counter-reset: item--numbered;

  & li::before {
    content: counter(item--numbered);
    width: 32px;
    height: 32px;
    border-radius: var(--radius-half);
    background-color: var(--color_bg--primary);
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik, Arial, sans-serif;
    font-size: 16px;
    color: var(--color--accent-tertiary);
  }
}

.list_view--simple li::before {
  width: 3px;
  height: 3px;
  background-color: var(--color_text--secondary);
}