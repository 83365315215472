.main-header__menu {
  border-bottom: 1px solid var(--color_line--primary);
  border-top: 1px solid var(--color_line--primary);
  padding: 8px 0;
  display: none;
  position: relative;
}

.main-header__menu-list {
  display: flex;
  justify-content: space-between;
}

.main-header__menu-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
}

.main-header__menu-item--services {
  position: relative;
}

.main-header__services {
  display: none;
  position: absolute;
  width: 50vw;
  left: 0;
  bottom: -20px;
  transform: translateY(100%);
  z-index: 2;
  background-color: var(--color_bg--primary-light);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid var(--color_line--primary);

  &::after {
    content: '';

    position: absolute;

    width: calc(100% + 60px);
    height: calc(100% + 60px);

    left: -30px;
    top: -30px;

    z-index: -1;
  }
}

.main-header__menu-item--services-arrow {
  transition: 0.5s ease-in-out;
}

.main-header__menu-item--services:hover {
  .main-header__services {
    display: grid;
  }

  .main-header__menu-item--services-arrow {
    transform: rotate(180deg);
  }
}

@media (min-width: 1200px) {
  .main-header__menu {
    display: block;
  }
}
