.tab {
  position: absolute;
  pointer-events: none;
  opacity: 0;

  &:focus-visible + label {
    outline: 2px solid var(--color--accent);
    outline-offset: 2px;
  }
}

.tab + label {
  padding: 9px 19px;
  background-color: var(--color_bg--primary-light);
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
  color: var(--color_text--tertiary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;

  &:hover {
    border: 1px solid var(--color--accent);
    background-color: var(--color--accent-tertiary);
    color: var(--color--accent);
  }
}

.tab:checked + label {
  background-color: var(--color--accent);
  color: var(--color_text--primary-inverse);
  border: 1px solid var(--color--accent);
}
