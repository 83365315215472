.select[open] .select__current .select__arrow {
  transform: rotate(180deg);
}

.select_view--button {
  .select__current {
    padding: 0 0 0 16px;
    position: relative;
    z-index: 1;

    &::after {
      height: 100%;
      aspect-ratio: 1 / 1;
      outline: 1px solid var(--color--accent-tertiary);
      border-radius: 12px;
      background-color: var(--color--accent-tertiary);
      content: '';
      right: 0;
      position: absolute;
      z-index: -1;
    }

    .select__arrow {
      margin-left: 8px;
      border-radius: 12px;
    }
  }
}

.select__current {
  padding: 7px 16px;
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;


  &:hover {
    color: var(--color--accent);
  }

  .select__arrow {
    transform: rotate(0);
    transition: 0.3s ease-in-out transform;
  }
}

.select__list {
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
  margin-top: 8px;
  position: absolute;
  background-color: var(--color_bg--primary-light);
  z-index: 1;
}

.select__item {
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  color: var(--radius-base);

  &:hover {
    color: var(--color--accent-hover);

    .icon {
      color: var(--color--accent-hover);
    }
  }

  &:active {
    color: var(--color--accent-active);

    .icon {
      color: var(--color--accent-active);
    }
  }
}

.select__item .link, .select__item .button {
  width: 100%;
  padding: 12px;
}

.select__item .button {
  justify-content: flex-start;
}

.select__item .icon {
  color: var(--color_icon--tertiary);

  transform: rotate(0);
  transition: 0.3s ease-in-out transform;
}

.select__item--current {
  color: var(--color--accent);
}

.select__item--current .icon {
  color: var(--color--accent);
}

.select--form {
  .select__item {
    padding: 0 12px 0 0;
    cursor: pointer;

    .select__item-caption {
      width: max-content;
    }
  }

  .select__item:focus-visible {
    outline: 1px solid var(--color--accent);
  }

  .select__item-caption:hover {
    color: var(--color--accent-hover);

    & ~ .select__item-icon-direction .icon {
      color: var(--color--accent-hover);
    }
  }

  .select__item-caption:active {
    color: var(--color--accent-active);

    & ~ .select__item-icon-direction .icon {
      color: var(--color--accent-active);
    }
  }

  .select__item-caption {
    padding: 12px 0 12px 12px;
  }

  .select__item-icon-direction {
    padding: 12px 0;
  }

  .select__item .icon {
    color: var(--color_icon--tertiary);

    transform: scaleY(1);
    transition: 0.3s ease-in-out transform;
  }

  .select__item--active .select__item-caption {
    color: var(--color--accent);
  }

  .select__item--active .select__item-icon-direction .icon {
    color: var(--color--accent);
  }

  .select__item-icon-direction {
    transition: 0.3s ease-in-out;
    transform: scaleY(1);
  }

  .select__item--reverse .select__item-icon-direction {
    transform: scaleY(-1);
  }
}
