.text_size--10 {
  font-size: 10px;
  line-height: 14px;
}
.text_size--12 {
  font-size: 12px;
  line-height: 16px;
}
.text_size--13 {
  font-size: 13px;
}
.text_size--14 {
  font-size: 14px;
  line-height: 20px;
}
.text_size--14-16-16 {
  font-size: 14px;
  line-height: 20px;

  @media (min-width: 720px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.text_size--16 {
  font-size: 16px;
  line-height: 20px;
}
.text_size--18 {
  font-size: 18px;
}
.text_size--19 {
  font-size: 19px;
}
.text_size--20 {
  font-size: 20px;
}
.text_size--20 {
  font-size: 20px;
}

.text_line-height--100 {
  line-height: 100%;
}
.text_line-height--110 {
  line-height: 110%;
}
.text_line-height--120 {
  line-height: 120%;
}
.text_line-height--125 {
  line-height: 125%;
}
.text_line-height--130 {
  line-height: 130%;
}
.text_line-height--140 {
  line-height: 140%;
}
.text_line-height--150 {
  line-height: 150%;
}
.text_line-height--170 {
  line-height: 170%;
}
.text_line-height--175 {
  line-height: 175%;
}

.text_weight--300 {
  font-weight: 300;
}
.text_weight--400 {
  font-weight: 400;
}
.text_weight--500 {
  font-weight: 500;
}
.text_weight--600 {
  font-weight: 600;
}
.text_weight--700 {
  font-weight: 700;
}

.text_view--base {
  color: var(--color_text--base);
}
.text_view--accent {
  color: var(--color--accent);
}
.text_view--primary {
  color: var(--color_text--primary);
}
.text_view--primary-inverse {
  color: var(--color_text--primary-inverse);
}
.text_view--secondary {
  color: var(--color_text--secondary);
}
.text_view--secondary-light {
  color: var(--color_text--secondary-light);
}

.text_view--tertiary {
  color: var(--color_text--tertiary);
}

.text_align--center {
  text-align: center;
}
