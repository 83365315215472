.search {
  padding-bottom: 80px;
}

.search__title {
  padding-top: 32px;
  padding-bottom: 32px;
}

.search__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}
