.space_bottom-s {
  margin-bottom: calc(2 * var(--spacer));
}
.space_bottom-m {
  margin-bottom: calc(4 * var(--spacer));
}
.space_bottom-l {
  margin-bottom: calc(4 * var(--spacer));

  @media (min-width: 1200px) {
    margin-bottom: calc(5 * var(--spacer));
  }
}
.space_bottom-l-l-l {
  margin-bottom: calc(5 * var(--spacer));
}
.space_bottom-xl {
  margin-bottom: calc(4 * var(--spacer));

  @media (min-width: 720px) {
    margin-bottom: calc(5 * var(--spacer));
  }

  @media (min-width: 1200px) {
    margin-bottom: calc(8 * var(--spacer));
  }
}
.space_bottom-xxl {
  margin-bottom: calc(5 * var(--spacer));

  @media (min-width: 720px) {
    margin-bottom: calc(8 * var(--spacer));
  }

  @media (min-width: 1200px) {
    margin-bottom: calc(10 * var(--spacer));
  }
}
.space_bottom-4xl {
  margin-bottom: calc(8 * var(--spacer));

  @media (min-width: 720px) {
    margin-bottom: calc(10 * var(--spacer));
  }

  @media (min-width: 1200px) {
    margin-bottom: calc(16 * var(--spacer));
  }
}
.space_bottom-5xl {
  margin-bottom: calc(10 * var(--spacer));

  @media (min-width: 720px) {
    margin-bottom: calc(16 * var(--spacer));
  }

  @media (min-width: 1200px) {
    margin-bottom: calc(32 * var(--spacer));
  }
}


.space_padding-s {
  padding: calc(2 * var(--spacer));
}
.space_padding-m {
  padding: calc(4 * var(--spacer));
}
.space_padding-l {
  padding: calc(5 * var(--spacer));
}
.space_padding-xl {
  padding: calc(5 * var(--spacer));

  @media (min-width: 1200px) {
    padding: calc(8 * var(--spacer));
  }
}
.space_padding-xxl {
  padding: calc(8 * var(--spacer));

  @media (min-width: 720px) {
    padding: calc(15 * var(--spacer));
  }

  @media (min-width: 1200px) {
    padding: calc(25 * var(--spacer));
  }
}

.space_padding-top-bottom-s {
  padding-top: calc(2 * var(--spacer));
  padding-bottom: calc(2 * var(--spacer));
}
.space_padding-top-bottom-m {
  padding-top: calc(4 * var(--spacer));
  padding-bottom: calc(4 * var(--spacer));
}
.space_padding-top-bottom-l {
  padding-top: calc(5 * var(--spacer));
  padding-bottom: calc(5 * var(--spacer));
}
.space_padding-top-bottom-xl {
  padding-top: calc(5 * var(--spacer));
  padding-bottom: calc(5 * var(--spacer));

  @media (min-width: 1200px) {
    padding-top: calc(8 * var(--spacer));
    padding-bottom: calc(8 * var(--spacer));
  }
}
.space_padding-top-bottom-xxl {
  padding-top: calc(8 * var(--spacer));
  padding-bottom: calc(8 * var(--spacer));

  @media (min-width: 720px) {
    padding-top: calc(15 * var(--spacer));
    padding-bottom: calc(15 * var(--spacer));
  }

  @media (min-width: 1200px) {
    padding-top: calc(25 * var(--spacer));
    padding-bottom: calc(25 * var(--spacer));
  }
}

.space_padding-sides--empty {
  padding-left: 0;
  padding-right: 0;
}



