.empty-plug__wrap {
  background-image: url("../../../public/img/purchase-history-bg-mobile.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-bottom: 32px;
}

.empty-plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  border-radius: 12px;
  background-image: url("../../../public/img/purchase-history-corner-bl.svg"), url("../../../public/img/purchase-history-corner-br.svg"), url("../../../public/img/purchase-history-corner-tl.svg"), url("../../../public/img/purchase-history-corner-tr.svg");
  background-position: bottom left, bottom right, top left, top right;
  background-repeat: no-repeat;
  background-size: 116px auto, 116px auto, 116px auto, 116px auto;
}

.empty-plug__image {
  width: 64px;
  height: 64px;
}

.empty-plug__action {
  width: 255px;
  @media (width <= 720px) {
    border-radius: 100rem;
  }
}

@media (width >= 720px) {
  .empty-plug__wrap {
    background-image: url("../../../public/img/purchase-history-bg-tablet.svg");
  }
}

@media (width >= 1200px) {
  .empty-plug__wrap {
    background-image: url("../../../public/img/purchase-history-bg-desktop.svg");
  }

  .empty-plug__title {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .empty-plug__image {
    width: 80px;
    height: 80px;
  }
}
