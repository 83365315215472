:root {
  --scroll-width: 0px;
}

.main-page {
  background-color: var(--color_bg--primary);
}

body.no-scroll {
  overflow: hidden;
  margin-right: var(--scroll-width);
}
