.goods-card {
  flex-grow: 0;
  background-color: var(--color_bg--primary-light);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px 20px;
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
}

.goods-card__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 48px;
}

.goods-card__img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.goods-card__inner {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.goods-card__inner-bottom {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.goods-card__prices-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goods-card__prices-caption {
  display: none;
}

.goods-card__prices {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.goods-card__button {
  border-radius: 8px;
  margin-left: auto;
}

.goods-card__inner-bottom .cheap__list  {
  margin-bottom: 0;
  margin-top: auto;
}

.goods-card__img {
  cursor: pointer;
}

.goods-card .cheap {
  cursor: pointer;
}

@media (width >= 720px) {
  .goods-card {
    flex-direction: row;
    padding: 16px;
  }

  .goods-card__img {
    width: 180px;
    height: 180px;
    border-radius: 12px;
  }

  .goods-card__inner {
    padding: 0;
    width: 100%;
  }

  .goods-card__prices-caption {
    display: block;
  }

  .goods-card__prices {
    flex-direction: row;
    gap: 8px;
  }
}

@media (width >= 1200px) {
  .goods-card {
    padding: 19px;
    border-radius: 16px;
  }

  .goods-card__button {
    border-radius: 12px;
  }

  .goods-card--narrow {
    flex-direction: column;
    padding: 0;

    .goods-card__img {
      width: 100%;
      height: 200px;
      border-radius: 8px 8px 0 0;
    }

    .goods-card__inner {
      padding: 16px;
    }

    .goods-card__prices-caption {
      display: none;
    }

    .goods-card__prices {
      flex-direction: column-reverse;
    }
  }
}
