.field {
  display: block;
  width: 100%;
  padding: 9px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color_text--primary);
  background-color: var(--color_bg--primary-light);
  border: 1px solid var(--color_line--primary);
  border-radius: calc(2 * var(--radius-base));
  background-clip: padding-box;
  caret-color: var(--color--accent);
  font-family: 'Montserrat', Arial, sans-serif;
}

.field--textarea {
  resize: vertical;
  min-height: 70px;

  &::-webkit-resizer {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8995 0.585938C11.1182 -0.195312 9.85262 -0.195312 9.07137 0.585938L0.586992 9.07227C0.157305 9.5 -0.0341017 10.0742 0.00496083 10.6328C0.0401171 11.0938 0.23543 11.5469 0.586992 11.9004C1.00496 12.3164 1.55965 12.5117 2.10262 12.4844C2.57918 12.4609 3.05184 12.2656 3.41512 11.9004L11.8995 3.41406C12.6807 2.63281 12.6807 1.36719 11.8995 0.585938Z' fill='%23CCD4E0'/%3E%3Cpath d='M13.8956 9.20312C13.9307 8.64844 13.7354 8.08203 13.3136 7.6582C12.5323 6.87695 11.2667 6.87695 10.4854 7.6582L7.6573 10.4863C6.87605 11.2676 6.87605 12.5332 7.6573 13.3145C8.19637 13.8535 8.9698 14.0215 9.6534 13.8145C9.95809 13.7227 10.2432 13.5566 10.4854 13.3145L13.3136 10.4863C13.6729 10.1289 13.8643 9.66992 13.8956 9.20312Z' fill='%23CCD4E0'/%3E%3C/svg%3E%0A");
  }
}

.field--textarea {
  resize: vertical;
}

.field_size--s {
  padding: 10.5px 8px;
  height: 40px;
}

.field__wrapper {
  position: relative;
  height: min-content;
  width: 100%;
}

.field:focus {
  border-color: var(--color--accent);
  outline: none;
}

.field:disabled {
  border-color: var(--color--disabled-secondary);
  background-color: var(--color--disabled-secondary);
  color: var(--color--disabled);
}
.field:disabled::placeholder {
  color: var(--color--disabled);
}

.field::placeholder {
  color: var(--color_text--placeholder);
}
input:not(:disabled).field_state--error {
  border-color: var(--color--error);
}
input:not(:disabled).field_state--warning {
  border-color: var(--color--warning);
}
input:not(:disabled).field_state--success {
  border-color: var(--color--success);
}
textarea:not(:disabled).field_state--error {
  border-color: var(--color--error);
}
textarea:not(:disabled).field_state--warning {
  border-color: var(--color--warning);
}
textarea:not(:disabled).field_state--success {
  border-color: var(--color--success);
}

.field__message {
  display: none;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  position: absolute;
  text-align: right;
  font-size: 12px;
  line-height: 16px;
}

.field_state--error ~ .field__message {
  color: var(--color--error);
  display: block;
}
.field_state--warning ~ .field__message {
  color: var(--color--warning);
  display: block;
}

.field:disabled ~ .field__message {
  color: var(--color--disabled);
}

.field_view--icon {
  padding-right: 58px;
  background-repeat: no-repeat;
  background-position: right 16px center, right;
  background-size: 24px, 56px;

  &.field--textarea {
    background-position: left 8px top 8px;
  }
}

.field_view--action {
  padding-right: 58px;
}

.field_view--bordered {
  background: none;
  color: var(--color_text--primary-inverse);
}

.field__action {
  position: absolute;
  top: 0;
  right: -1px;
  color: var(--color--accent);
  height: 100%;
  width: 40px;
  border-radius: 0 calc(2 * var(--radius-base)) calc(2 * var(--radius-base)) 0;
  background-color: var(--color--accent-tertiary);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 720px) {
    width: 58px;
  }
}

.field__action--bordered {
  background-color: transparent;
  border-left: 1px solid var(--color_line--primary);

  @media (min-width: 720px) {
    width: 40px;
  }
}


.field_size--s ~ .field__action {
  top: 8px;
}

.field__action:hover {
  background: var(--color--accent-tertiary-hover);
}
.field__action:active {
  background: var(--color--accent-tertiary-active);
}
.field__action:disabled {
  color: var(--color--disabled);
}

.field__action--first {
  .icon:last-child {
    display: none;
  }
}

.field__action--second {
  .icon:first-child {
    display: none;
  }
}

.field__action--accent {
  background-color: var(--color--accent);
  color: var(--color--accent-tertiary);
}
.field__action--accent:hover {
  background-color: var(--color--accent-hover);
}
.field__action--accent:active {
  background-color: var(--color--accent-active);
}
.field__action--accent:disabled {
  color: var(--color--disabled);
}

.field_type--search {
  background-image:
          url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.78283 2.91797C5.99699 2.91797 2.91699 5.99714 2.91699 9.78297C2.91699 13.5688 5.99699 16.6488 9.78283 16.6488C13.5678 16.6488 16.6478 13.5688 16.6478 9.78297C16.6478 5.99714 13.5678 2.91797 9.78283 2.91797ZM9.78283 17.8988C5.30783 17.8988 1.66699 14.258 1.66699 9.78297C1.66699 5.30797 5.30783 1.66797 9.78283 1.66797C14.2578 1.66797 17.8978 5.30797 17.8978 9.78297C17.8978 14.258 14.2578 17.8988 9.78283 17.8988Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.9291 18.9368C17.7699 18.9368 17.6099 18.8759 17.4874 18.7543L14.5507 15.8259C14.3066 15.5818 14.3057 15.1859 14.5499 14.9418C14.7932 14.6959 15.1891 14.6976 15.4341 14.9401L18.3707 17.8693C18.6149 18.1134 18.6157 18.5084 18.3716 18.7526C18.2499 18.8759 18.0891 18.9368 17.9291 18.9368Z' fill='white'/%3E%3C/svg%3E%0A"),
          linear-gradient(to left, var(--color--accent) 58px, transparent 58px);
}

.field_type--search:focus {
  background-image: none;
}
.field_type--search:not(:placeholder-shown) {
  background-image: none;
}
