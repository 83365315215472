.cheap {
  border-radius: var(--radius-half);
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  height: max-content;

  &:first-letter {
    text-transform: uppercase;
  }
}

.cheap_size--m {
  padding: 6px 12px;
}

.cheap_size--s {
  padding: 4px 8px;
}

.cheap_view--accent {
  background-image: linear-gradient(100deg, var(--gradient-accent));
  color: var(--color_text--primary-inverse);
}

.cheap_view--primary {
  background-color: var(--color_bg--base);
  color: var(--color_text--primary-inverse);
}

.cheap_view--accent-tertiary-hover:hover {
  background-color: var(--color--accent);
  color: var(--color--accent-tertiary);
  border: 1px solid var(--color--accent);
  outline: none;
}

.cheap_view--accent-tertiary {
  background-color: var(--color--accent-tertiary);
  color: var(--color--accent);
  border: 1px solid var(--color--accent);
}

.cheap_view--accent-tertiary-bordered {
  background-color: var(--color--accent-tertiary);
  color: var(--color--accent);
}

.cheap_view--bordered {
  background-color: var(--color_bg--primary);
  color: var(--color_text--tertiary);
  border: 1px solid var(--color_line--primary);

  .cheap__count {
    color: var(--color_text--base);
  }
}

.cheap_view--bordered-hover:hover {
  border: 1px solid var(--color--accent);
  color: var(--color--accent);

  .cheap__count {
    color: var(--color--accent);
  }
}

.cheap__list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

@media (width >= 1200px) {
  .cheap {
    padding: 8px 16px;
  }

  .cheap_size--s {
    padding: 4px 8px;
  }
}
