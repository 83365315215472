.filter__inner {
  background-color: var(--color_bg--primary-light);
  padding-bottom: 20px;
}

.filter__top {
  display: flex;
  align-items: center;
  padding: 32px;
  border-bottom: 1px solid var(--color_line--primary);
  margin-bottom: 20px;
}

.filter__top-toggle {
  display: none;

  transform: rotate(180deg);
  transition: 0.5s ease-in-out;
}

.filter__reset {
  margin-left: 20px;
  display: none;
}

.filter__close {
  margin-left: auto;
  display: none;
}

.catalog--filter-open, .catalog--category-open {
  .filter__reset {
    display: block;
  }

  .filter__close {
    display: block;
  }
}

.filter__fieldset {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color_line--primary);
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
}

.filter__fieldset legend {
  width: 100%;
  display: flex;
  gap: calc(var(--spacer) * 4);
  justify-content: space-between;
  align-items: flex-start;

  svg {
    flex-shrink: 0;
  }
}

.input__wrapper {
  position: relative;

  //&:hover::after {
  //  content: '';
  //  width: calc(100% + 32px);
  //  height: calc(100% + 16px);
  //  left: -16px;
  //  background-color: var(--color--accent-tertiary);
  //  position: absolute;
  //  z-index: -1;
  //}
}

.filter__fieldset--toggle {
  legend .icon {
    transition: 0.5s ease-in-out;
  }

  &.filter__fieldset--open {
    .filter__fieldset-inner {
      transform: scaleY(1);
      height: auto;
    }

    legend .icon {
      transform: rotate(180deg);
    }
  }

  &.filter__fieldset--close {
    padding-bottom: 0;

    .filter__fieldset-inner {
      transform: scaleY(0);
      height: 0;
    }

    legend .icon {
      transform: rotate(0deg);
    }
  }
}

.filter__fieldset-spoiler {
  flex-direction: column;
  gap: 16px;
  display: flex;
  max-height: 0;
  overflow: hidden;
}

.filter__fieldset-inner {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.filter__fieldset-spoiler--open, .filter__fieldset-spoiler:focus-within {
  max-height: max-content;
  overflow: visible;
}

.filter__fieldset-spoiler--open ~ .filter__fieldset-spoiler-open, .filter__fieldset-spoiler:focus-within ~ .filter__fieldset-spoiler-open  {
  margin-top: -8px;

  .icon {
    transform: rotate(180deg);
  }
}

.filter__fieldset-spoiler-open {
  width: max-content;
  flex-shrink: 1;
  margin-top: -24px;

  .icon {
    transform: rotate(0);

    transition: 0.5s ease-in-out;
  }
}

.filter__range-wrap {
  display: flex;

  .field {
    padding: 10px;
    border: none;
    background-color: var(--color_bg--primary);
  }
}

.filter__range {
  height: 16px;
}

.filter__confirm {
  display: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 32px;
}

.catalog--filter-open {
  .filter__confirm {
    display: block;
  }
}



@media (width >= 1200px) {
  .filter__top {
    padding: 20px 16px 16px;

    justify-content: space-between;
    align-items: center;
  }

  .filter__top-toggle {
    display: block;
  }

  .filter--close {
    .filter__inner {
      padding-bottom: 0;
    }

    .filter__top {
      border-bottom: none;
      margin-bottom: 0;
    }

    .filter__fieldset {
      display: none;
    }

    .filter__top-toggle {
      transform: rotate(0deg);
    }
  }
}
