.article-card {
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--spacer));
}

.article-card__img {
  border-radius: var(--radius-base);
  aspect-ratio: 35 / 26;
  width: 100%;
  object-fit: cover;
  height: auto;
}

.article-card__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.article-card__info-view {
  display: flex;
  gap: 5px;
  align-items: center;
}
