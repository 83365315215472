.description-details {
  display: flex;
  gap: 14px;
}

.description-details li dl {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color_line--base);
}

.description-details li dl dt {
  color: var(--color_text--tertiary);
}

.description-details__main {
  padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: var(--radius-half);
    background-color: var(--color--accent);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (width >= 1200px) {
  .description-details--hide li dl > *:not(.description-details__main) {
    display: block;
  }

  .description-details--hide {
    flex-direction: column;
    border: none;
    padding: 0;
  }

  .description-details__main {
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

@media (width <= 1200px) {
  .description-details--hide li dl > *:not(.description-details__main) {
    display: none;
  }

  .description-details--hide {
    flex-direction: row;
    gap: 8px;
    border-top: 1px solid var(--color_line--base);
    border-bottom: 1px solid var(--color_line--base);
    padding: 16px 0;
  }

  .description-details--hide li dl {
    padding: 0;
    border: none;
  }
}