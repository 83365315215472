.privacy {
  margin-bottom: 32px;
}

.privacy__title {
  padding-top: 24px;
  padding-bottom: 24px;
}

.privacy__nav {
  display: none;
}

.privacy__section {
  border-radius: 12px;
  padding: 16px;
  background-color: var(--color_bg--primary-light);
  scroll-margin: 32px;
}

.privacy__text {
  margin-bottom: 21px;
}

@media (width >= 720px) {
  .privacy {
    margin-bottom: 48px;
  }
}

@media (width >= 1200px) {
  .privacy {
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 255px auto;
    gap: 0 30px;
  }

  .privacy__title {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .privacy__nav {
    display: block;
    position: sticky;
    top: 32px;
    height: max-content;
    background-color: var(--color_bg--primary-light);
    overflow: hidden;
  }

  .privacy__nav-item a {
    display: block;
    padding: 16px;

    &:hover {
      color: var(--color--accent);
    }

    &#user:target{
      background-color:gold;
    }
  }

  .privacy__nav-item--active a {
    background-color: var(--color--accent);
    color: var(--color_text--primary-inverse);

    &:hover {
      color: var(--color_text--primary-inverse);
      cursor: default;
    }
    &:active {
      opacity: 1;
    }
  }

  .privacy__section {
    border-radius: 16px;
    padding: 32px;
    grid-column: 2 / 3;
  }

  .privacy__text {
    margin-bottom: 20px;
  }
}