.radius--4 {
  border-radius: 4px;
}

.radius--5 {
  border-radius: 5px;
}

.radius--8 {
  border-radius: 8px;
}

.radius--12 {
  border-radius: 12px;
}

.radius--16 {
  border-radius: 16px;
}