.sorting {
  display: flex;
  justify-content: center;
  position: relative;

  .select__current {
    border-width: 0;
    padding: 10px;
  }
}

.sorting__label {
  display: none;
}

@media (width >= 1200px) {
  .sorting {
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    .select__current {
      border-width: 1px;
      padding: 7px 16px;

      flex-direction: row-reverse;
    }
  }

  .sorting__label {
    display: block;
  }
}
