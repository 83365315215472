.details {
  padding: 16px;
  background-color: var(--color_bg--primary-light);
  border: 1px solid var(--color_line--primary);
  border-radius: calc(var(--radius-base) * 3);
}

.summary {
  list-style: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  padding-right: 24px;

  position: relative;
  &::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    width: 24px;
    height: 24px;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_4373_67975' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_4373_67975)'%3E%3Cpath d='M12 15L7 10H17L12 15Z' fill='url(%23paint0_linear_4373_67975)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4373_67975' x1='7' y1='10' x2='17.5301' y2='13.3173' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235F84FE'/%3E%3Cstop offset='1' stop-color='%232A5BFC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

    transition: 0.5s ease-in-out;

    content: '';
  }
}

.details[open] .summary::after {
  transform: translateY(-50%) rotate(180deg);
}

.details__answer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--color_line--primary);
  font-size: 14px;
  line-height: 20px;
  color: var(--color_text--base);
}

.details__answer-desc {
  margin-bottom: 8px;
  color: var(--color_text--tertiary);
}
