.profile__setting-wrapper {
  margin-bottom: 32px;
}

.profile__title {
  padding-top: 24px;
  padding-bottom: 24px;
}

.profile__password {

}

@media (width >= 720px) {
  .profile__setting-wrapper {
    margin-bottom: 48px;
  }

  .profile__setting {
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
  }

  .profile__password-field-wrapper {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .profile__password-field {
    height: 40px;
  }

  .profile__password {
    display: flex;
    align-items: flex-end;
  }

  .profile__password-update-button {
    margin-left: -20px;
    padding: 9px 16px 9px 32px;
    border: 1px solid var(--color--accent-tertiary);
    width: 185px;
    flex-shrink: 0;

    &:hover {
      border: 1px solid var(--color--accent-tertiary-hover);
    }

    &:disabled {
      border: 1px solid var(--color--disabled);
    }
  }
}

@media (width >= 1200px) {
  .profile__setting-wrapper {
    margin-bottom: 192px;
  }

  .profile__title {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .profile__setting {
    width: 540px;
  }
}