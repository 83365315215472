.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  border-radius: calc(2 * var(--radius-base));
  background: none;
  cursor: pointer;
  width: 100%;
  gap: 10px;
  border: none;
  font-family: Montserrat, Arial, sans-serif;
  padding: 0;

  .icon {
    flex-shrink: 0;
  }
}

.button_size--xs {
  padding: 10px 27px;
  width: auto;
}

.button_size--s {
  padding: 10px 27px;
  width: auto;
}

.button_size--m, .button_size--l {
  padding: 14px 20px;
}

.button_size--wide {
  padding-left: 27px;
  padding-right: 27px;
}

.button_size--full {
  width: 100%;
}


.button_view--primary {
  background-color: var(--color--accent);
  color: var(--color_text--primary-inverse);

  &:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    background-color: var(--color--accent-tertiary);
    outline: 1px solid var(--color--accent);
    color: var(--color--accent);
  }

  &:disabled {
    background-color: var(--color--disabled);
  }
}

.button_view--tertiary {
  background-color: var(--color--accent-tertiary);
  color: var(--color--accent);

  &:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    background-color: var(--color--accent-tertiary-hover);
  }

  &:disabled {
    background-color: var(--color--disabled);
  }
}

.button_view--simple {
  border-radius: 0;
  width: max-content;

  &:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    color: var(--color--disabled);
  }
}

.button_view--bordered {
  background-color: transparent;
  color: var(--color_text--base);
  border: 1px solid var(--color_line--primary);

  &:hover {
    color: var(--color--accent);
  }

  &:disabled {
    border-color: var(--color--disabled);
    color: var(--color--disabled);
  }
}

.button_view--control {
  border-radius: var(--radius-half);
  padding: 8px;
  background-color: var(--color_bg--primary);
  color: var(--color_line--primary-active);
}

.button_view--circle {
  background: none;
  outline: none;
  color: inherit;
  padding: 0;
  display: flex;
}

.button_view--link {
  border-radius: 0;
  background: none;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  width: max-content;

  &:not(:focus-visible) {
    outline: none;
  }

  &:disabled {
    background-color: var(--color--disabled);
  }
}

.button_view--link--underline {
  text-decoration: underline;

  &:disabled {
    background-color: var(--color--disabled);
  }
}

.button_view--burger {
  .button_view--burger__rects {
    transform-origin: 13px 13px;

    transition: 0.25s;
  }
  .button_view--burger__top-rect {
    transform-origin: 13px 5px;

    transition: 0.25s 0.25s;
  }
  .button_view--burger__middle-rect {
    transform-origin: 13px 13px;

    transition: 0.25s;
  }
  .button_view--burger__bottom-rect {
    transform-origin: 13px 21px;

    transition: 0.25s 0.25s;
  }
}

.button_view--burger--open {
  .button_view--burger__top-rect {
    transform: translate(0, 8px);

    transition: 0.25s 0s;
  }
  .button_view--burger__middle-rect {
    transform: rotate(90deg);

    transition: transform 0.25s 0.25s;
  }
  .button_view--burger__bottom-rect {
    transform: translate(0, -8px);

    transition: 0.25s 0s;
  }
  .button_view--burger__rects {
    transform: rotate(45deg);

    transition: transform 0.25s 0.25s;
  }
}

.button_behavior--hide-text {
  width: auto;
  padding: 10px;

  span {
    display: none;
  }
}

@media (min-width: 720px) {
  .button {
    width: auto;
  }

  .button_size--full {
    width: 100%;
  }

  .button_size--s {
    padding: 10px 20px;
  }
  .button_size--m {
    padding: 14px 20px;
  }
  .button_size--l {
    padding: 14px 40px;
  }
  .button_size--wide {
    padding-left: 20px;
    padding-right: 20px;
  }

  .button_size--s--circle {
    padding: 4px;
  }
  .button_size--m--circle {
    padding: 8px;
  }
  .button_size--l--circle {
    padding: 16px;
  }

  .button_behavior--hide-text {
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .button_behavior--hide-text {
    padding: 9px 16px;

    span {
      display: block;
    }
  }
}
