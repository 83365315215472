.main-footer {
  background-color: var(--color_text--base);
  color: var(--color_text--primary-inverse);

  padding: 32px 0 20px;

  margin-top: auto;
}

.main-footer__logo {
  display: flex;
}

.main-footer__title {
  padding-bottom: 12px;
  border-bottom: 1px solid #2E323F;
}

.main-footer__logo .icon {
  width: 156px;
  height: auto;
}

.main-footer__inner {
  display: grid;
  gap: 32px 30px;
  grid-template-columns: 1fr;
}

.main-footer__about {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-footer__logo {
  color: var(--color_text--primary-inverse);
}

.main-footer__cell {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-footer__list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  li a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.main-footer__list-about {
  margin-bottom: 36px;
}

.main-footer__form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-footer__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-footer__networks {
  display: flex;
  gap: 16px;
}

.main-footer__networks .link {
  border-radius: var(--radius-half);
}

.main-footer__copy {
  border-top: 1px solid var(--color_line--base);
  margin-top: 20px;
}

.main-footer__copy-inner {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 720px) {
  .main-footer {
    padding: 48px 0 32px;
  }

  .main-footer__inner {
    grid-template-columns: 1fr 1fr;
  }

  .main-footer__about {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px 30px;
  }

  .main-footer__form-wrapper {
    grid-column: 1 / -1;
  }

  .main-footer__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px 30px;
  }

  .main-footer__copy-inner {
    flex-direction: row;
    justify-content: space-between;
  }

  .main-footer__copy {
    margin-top: 32px;
  }
}

@media (width >= 1200px) {
  .main-footer {
    padding: 64px 0 20px;
  }

  .main-footer__inner {
    grid-template-columns: repeat(4, 1fr);
  }

  .main-footer__cell {
    gap: 20px;
  }

  .main-footer__about {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: 1 / -1;
  }

  .main-footer__catalog {
    grid-column: 1 / 4;
  }

  .main-footer__catalog-inner {
    gap: 32px 30px;
  }

  .main-footer__form-wrapper {
    grid-column: -2 / -1;
    gap: 20px;
  }

  .main-footer__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .main-footer__copy-inner {
    flex-direction: row;
    justify-content: space-between;
  }

  .main-footer__copy {
    margin-top: 40px;
  }
}
