.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: var(--mobile-width);
  }

  @media (min-width: 720px) {
    max-width: var(--tablet-width-m);
  }

  @media (min-width: 992px) {
    max-width: var(--tablet-width-l);
  }

  @media (min-width: 1200px) {
    max-width: var(--desktop-width);
    padding-right: 45px;
    padding-left: 45px;
  }
}

