.breadcrumbs__wrap {
  background-color: var(--color_bg--primary);
  border-bottom: 1px solid var(--color_line--primary);
}

.breadcrumbs {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
}

.breadcrumbs__item {
  &:not(:last-child)::after {
    content: '\A0/\A0';
    display: inline-block;
  }
  &:not(:last-child) {
    color: var(--color_text--tertiary);
  }
}

@media (width <= 1200px) {
  .breadcrumbs__wrap {
    display: none;
  }
}