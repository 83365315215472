.modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1000vh rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.modal__inner {
  background-color: var(--color_bg--primary-light);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 20px 16px 0;
  height: 100vh;
  height: var(--doc-height);
  flex-direction: column;
  align-items: flex-start;
  display: flex;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal__title {
  width: 100%;
}

.modal--open {
  display: block;

  .modal__inner {
    animation: show-popup 0.5s ease-in-out;
  }
}

.modal__bottom {
  margin-top: auto;
}

.modal__close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='%239BA3BE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='%239BA3BE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

@media (width <= 720px) {
  .modal__inner button[type='submit'] {
    order: 999;
    width: 100vw;
    margin-left: -16px;
    border-radius: 0;
    margin-bottom: 0;

    &:last-child {
      box-shadow: 0 1px 0 0 var(--color--accent);
      margin-bottom: 1px;
    }
  }
}

@media (width >= 720px) {
  .modal__desc {
    text-align: center;
  }

  .modal__inner {
    width: 450px;
    border-radius: 16px;
    padding: 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-height: calc(100vh - 80px);
  }

  .modal__title {
    text-align: center;
  }

  .modal__close {
    right: 32px;
    top: 32px;
  }

  .modal__bottom {
    margin-top: 0;
  }
}

@media (width >= 1200px) {
  .modal__inner {
    width: 540px;
  }

  .modal__confirm {
    width: 222px;
    margin-left: auto;
    margin-right: auto;
  }
}
