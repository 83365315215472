.grid {
  display: grid;
  grid-template-columns: 1fr;
}

.grid_col--4-2, .grid_col--6 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid__cell--wide {
  grid-column: 1 / -1;
}

@media (min-width: 720px) {
  .grid_col--5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid_col--4, .grid_col--4-2, .grid_col--12 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid_col--3, .grid_col--6 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid_col--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid_col--2-05 {
    grid-template-columns: 0.5fr 1fr;
  }

  .grid_view--odd > :last-child {
    grid-column: 1 / -1;
  }
}

@media (min-width: 1200px) {
  .grid_col--12 {
    grid-template-columns: repeat(12, 1fr);

    & > * {
      grid-column: 3 span;
    }

    &.grid_col--12--last-row-3 {
      & > *:nth-last-child(1) {
        grid-column: 4 span;
      }
      & > *:nth-last-child(2) {
        grid-column: 4 span;
      }
      & > *:nth-last-child(3) {
        grid-column: 4 span;
      }
    }
  }

  .grid_col--1-1-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid_col--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  .grid_col--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid_col--4, .grid_col--4-2 {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid_col--2-1, .grid_col--2-05 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid_gap--3xs {
  gap: 5px;
}

.grid_gap--xxs {
  gap: 10px;
}

.grid_gap--xxs-xs-xs {
  gap: 10px;

  @media (min-width: 720px) {
    gap: 20px;
  }
}

.grid_gap--s-m-m {
  gap: 16px;

  @media (min-width: 720px) {
    gap: 30px;
  }
}

.grid_gap--s-s-m {
  gap: 16px;

  @media (min-width: 1200px) {
    gap: 30px;
  }
}

.grid_row-gap--m-m-l {
  row-gap: 30px;

  @media (min-width: 1200px) {
    row-gap: 64px;
  }
}
