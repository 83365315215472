.icon {
  color: inherit;
}

.icon__size--s {
  width: 32px;
  height: 32px;
}
.icon__size--m {
  width: 40px;
  height: 40px;
}
.icon__size--l {
  width: 80px;
  height: 80px;
}

.icon_type--inline {
  display: inline-block;
  margin-right: var(--spacer);
}
.icon_type--block {
  display: block;
}

.icon_color--accent {
  color: var(--color--accent);
}
.icon_color--primary-inverse {
  color: var(--color--primary);
}
.icon_color--secondary {
  color: var(--color_icon--secondary);
}
.icon_color--tertiary {
  color: var(--color_icon--tertiary);
}

.icon_bg--accent {
  background-color: var(--color--accent);
  border-radius: var(--radius-base);
  color: var(--color--primary);
}

.icon_view--circle {
  background-color: var(--color--accent-secondary);
  color: var(--color--primary);
  border-radius: var(--radius-half);
  padding: 18px;
  box-sizing: content-box;
}

.icon_bg--accent-light {
  background-color: var(--color--accent-tertiary);
  border-radius: var(--radius-base);
}

.icon_space--s {
  padding: calc(2 * var(--spacer));
}

.icon_space--m {
  padding: calc(4 * var(--spacer));
}
