.top-category {

}

.top-category__list {

}

.top-category__link {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--color_line--primary);
  border-radius: 12px;

  background-image: url("data:image/svg+xml,%3Csvg width='186' height='74' viewBox='0 0 186 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M0 0L32 32H161.524L186 73.5V12C186 5.37258 180.627 0 174 0H0Z' fill='url(%23paint0_linear_4035_35217)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4035_35217' x1='0' y1='0' x2='185.779' y2='74.0534' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235F84FE'/%3E%3Cstop offset='1' stop-color='%232A5BFC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: top right;
  background-repeat: no-repeat;
  transition: 0.5s ease-in-out;

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='186' height='74' viewBox='0 0 186 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M0 0L32 32H161.524L186 73.5V12C186 5.37258 180.627 0 174 0H0Z' fill='url(%23paint0_linear_4035_35216)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4035_35216' x1='0' y1='0' x2='185.779' y2='74.0534' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235F84FE'/%3E%3Cstop offset='1' stop-color='%232A5BFC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

.top-category__line {
  margin-top: 0;
  border: 1px solid var(--color_line--primary);
}

@media (min-width: 720px) {
  .top-category {
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
  }

  .top-category__link {

  }
}
