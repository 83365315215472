@keyframes show-goods-popup {
  0% {
    transform: translateY(-200vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes show-popup {
  0% {
    transform: translate(-50%, -200vh);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
