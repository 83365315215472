.checkbox {
  position: absolute;
  pointer-events: none;
  opacity: 0;

  & + label {
    height: 24px;
    width: 24px;
    display: block;
    cursor: pointer;
    background: transparent;
    border-radius: var(--radius-base);
    position: relative;
    border: 1px solid var(--color_line--primary);
  }

  &:checked + label {
    background-color: var(--color--accent);
    border: 1px solid var(--color--accent);

    &::after {
      top: 0;
      opacity: 1;
    }
  }

  &:disabled + label {
    border-color: var(--color--disabled);
    background-color: var(--color--disabled);
  }
  &:disabled:checked + label {
    border-color: var(--color--disabled);
    background-color: var(--color--disabled);
  }

  &:not(:disabled):hover + label {
    border-color: var(--color_line--primary-hover);
  }
  &:not(:disabled):checked:hover + label {
    border-color: var(--color_bg--input-hover);
    background-color: var(--color_bg--input-hover);
  }

  &:focus-visible + label {
    outline: 2px solid var(--color--accent);
    outline-offset: 2px;
  }

  & + label::after {
    pointer-events: none;
    cursor: default;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -100%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.2022 5.79215C20.5993 6.18169 20.5993 6.81325 20.2022 7.20278L8.68632 18.5L3.79792 13.7044C3.40069 13.3147 3.40069 12.6829 3.79792 12.2933C4.1948 11.9039 4.83815 11.9035 5.23551 12.2924L8.68632 15.6694L18.7646 5.79182C19.1617 5.4026 19.8053 5.40275 20.2022 5.79215Z' fill='%23FBFBFB'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: 0.1s ease;
  }
}

.checkbox:not(:disabled).field_state--error + label {
  border-color: var(--color--error);
}