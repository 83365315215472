@import "design-system/design";

@import "vars";
@import "mixins";
@import "animation";

@import "service/font.scss";
@import "service/visuallyhidden.scss";

//@import "common/alert.scss";
@import "common/breadcrumbs.scss";
@import "common/cheap.scss";
@import "common/description-details.scss";
@import "common/empty-plug.scss";
@import "common/goods-card.scss";
//@import "common/hide.scss";
@import "common/input-range.scss";
@import "common/main-footer.scss";
@import "common/main-header.scss";
@import "common/main-nav.scss";
@import "common/modal.scss";
@import "common/page.scss";
@import "common/page-title.scss";
@import "common/pagination.scss";
@import "common/article-card.scss";
@import "common/details.scss";

@import "blocks/buy-goods.scss";
@import "blocks/catalog.scss";
@import "blocks/error-page.scss";
@import "blocks/filter.scss";
@import "blocks/goods-popup.scss";
@import "blocks/main-page.scss";
@import "blocks/privacy.scss";
@import "blocks/profile.scss";
@import "blocks/promo.scss";
@import "blocks/purchase-history.scss";
@import "blocks/search.scss";
@import "blocks/sorting.scss";
@import "blocks/article.scss";
@import "blocks/blog.scss";
@import "blocks/main-menu";
@import "blocks/top-category";
@import "blocks/goods-slider";
@import "blocks/contacts";
@import "blocks/about";
@import "blocks/dev-compound";

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--color_bg--primary);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
