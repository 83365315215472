.title {
  font-weight: 500;
  font-family: 'Montserrat', Arial, sans-serif;
  display: block;
}

.title_size--xxs {
  font-size: 16px;
  line-height: 20px;
}
.title_size--xs {
  font-size: 18px;
  line-height: 24px;
}
.title_size--s {
  font-size: 20px;
  line-height: 20px;
}
.title_size--m {
  font-size: 24px;
  line-height: 28px;

  @media (min-width: 1200px) {
    font-size: 26px;
    line-height: 26px;
  }
}
.title_size--l {
  font-size: 28px;
  line-height: 32px;

  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 32px;
  }
}

