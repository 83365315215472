.catalog__title {
  padding-top: 24px;
  padding-bottom: 24px;
}

.catalog__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
  padding-bottom: 32px;
}

.catalog__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  grid-column: 1 / -1;
  grid-row: 1 / 2;

  .catalog__tabs-item {
    flex-grow: 1;
  }
}

.filter__wrapper {
  display: none;
}

.catalog__filter, .catalog__category {
  display: none;
}

.catalog__filter-open-wrapper {
  display: flex;
  justify-content: center;
  margin-right: -15px;

  .catalog__filter-open {
    padding: 10px 16px;
    gap: 8px;
    width: max-content;

    &:hover {
      color: var(--color--accent);
    }
  }

  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: var(--color_line--primary);
    bottom: 0;
    left: -50vw;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: var(--color_line--primary);
    top: 0;
    left: -50vw;
  }
}

.catalog__filter-open-wrapper--catalog {
  border-right: 1px solid var(--color_line--primary);
}

.catalog__sorting {
  grid-column: 1 / -1;
  margin-left: -15px;
}

.catalog__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: 1 / -1;
}

.catalog__active-filter {
  display: none;
  align-items: center;
  gap: 16px;
  padding: 0 1px;
  grid-column: 1 / -1;

  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  }
}

.catalog--active-filter .catalog__active-filter {
  order: -1;
  display: flex;
}

.catalog--filter-open {
  .catalog__filter {
    display: block;
    padding: 0;
  }
}


.catalog--category-open {
  .catalog__category {
    display: block;
    padding: 0;
    height: max-content;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}

.catalog--filter-open, .catalog--category-open {
  .filter__wrapper {
    display: block;
    height: max-content;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  .catalog__filter-open-wrapper {
    display: none;
  }

  .catalog__list {
    display: none;
  }

  .catalog__sorting {
    display: none;
  }

  .catalog__pagination {
    display: none;
  }

  .catalog__tabs {
    display: none;
  }

  .catalog__active-filter {
    display: none;
  }
}

.catalog__active-filter-close {
  border-radius: var(--radius-half);
}

@media (width >= 720px) {
  .catalog__inner {
    padding-bottom: 48px;
  }

  .catalog__tabs {
    flex-wrap: nowrap;
  }

  .catalog__tabs-item {
    flex-grow: 0;
  }
}

@media (width >= 1200px) {
  .catalog__inner {
    grid-template-columns: 255px 1fr 1fr;
    grid-template-rows: 40px auto auto 64px;
    padding-bottom: 80px;
  }

  .catalog--active-filter {
    .catalog__inner {
      grid-template-rows: 40px 72px auto 64px;
    }

    .catalog__list {
      grid-row: 3 / 4;
    }

    .catalog__pagination {
      grid-row: 4 / 5;
    }
  }

  .catalog--active-filter .catalog__active-filter {
    padding: 0 20px;
    background-color: var(--color_bg--primary-light);
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    grid-column: 2 / -1;
  }

  .catalog__tabs {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .catalog__filter-open-wrapper {
    display: none;
  }

  .filter__wrapper {
    display: flex;
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    order: -1;
    flex-direction: column;
    gap: 20px 30px;
  }

  .catalog__filter {
    padding: 0;
    margin: 0;
    display: block;
  }

  .catalog__category {
    padding: 0;
    margin: 0;
    display: block;
  }

  .catalog__filter-open-wrapper {
    display: none;
  }

  .catalog__sorting {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    margin: 0;
  }

  .catalog__list {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }

  .catalog__pagination {
    grid-column: 2 / -1;
    grid-row: 4 / 5;
  }
}
