.about__info {
  grid-template-columns: 1fr 255px;
  align-items: center;
}

.about__info-logo {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--color_line--primary);
  background-color: var(--color_bg--primary-light);
  max-height: 150px;
  height: 100%;
}

.about__features {
  @media (min-width: 992px) {
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
    padding: 32px;
  }
}

.about__features-list {
  grid-template-columns: repeat(6, 1fr);

  .top-category__item {
    grid-column: 2 span;
  }

  .top-category__item:nth-last-child(1), .top-category__item:nth-last-child(2) {
    grid-column: 3 span;
  }
}

.about__history {
  @media (min-width: 992px) {
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
    padding: 32px;
  }
}

.about__history-item {
  padding: 16px;
  border: 1px solid var(--color_line--primary);
  border-radius: 12px;
  position: relative;

  &::before {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid var(--color--accent);
    background-color: var(--color_bg--primary-light);
    top: 16px;
    left: 16px;
    content: '';
    z-index: 1;
  }

  &::after {
    position: absolute;
    width: 2px;
    height: 105%;
    background-color: var(--color--accent);
    top: 36px;
    left: 25px;
    content: '';
    z-index: 2;
  }

  &:last-child::after {
    display: none;
  }

  dl {
    display: grid;
    gap: 16px;
    grid-template-columns: max-content 1fr;

    dt {
      font-size: 26px;
      line-height: 20px;
      padding-left: 36px;
    }

    dd {
      flex-shrink: 1;
    }
  }
}

.about__team {
  @media (min-width: 992px) {
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
    padding: 32px;
  }
}

.about__team-item-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--color_line--primary);
  border-radius: 12px;

  background-image: url("data:image/svg+xml,%3Csvg width='186' height='74' viewBox='0 0 186 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M0 0L32 32H161.524L186 73.5V12C186 5.37258 180.627 0 174 0H0Z' fill='url(%23paint0_linear_4035_35217)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4035_35217' x1='0' y1='0' x2='185.779' y2='74.0534' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235F84FE'/%3E%3Cstop offset='1' stop-color='%232A5BFC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: top right;
  background-repeat: no-repeat;
  transition: 0.5s ease-in-out;

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='186' height='74' viewBox='0 0 186 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M0 0L32 32H161.524L186 73.5V12C186 5.37258 180.627 0 174 0H0Z' fill='url(%23paint0_linear_4035_35216)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4035_35216' x1='0' y1='0' x2='185.779' y2='74.0534' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235F84FE'/%3E%3Cstop offset='1' stop-color='%232A5BFC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

.about__team-item-top {
  display: flex;
  align-items: center;
  gap: 15px;
}

.about__team-item-logo {
  border-radius: 50%;
}

.about__team-list {
  grid-template-columns: repeat(6, 1fr);

  .about__team-item {
    grid-column: 2 span;
  }

  .about__team-item:nth-last-child(1), .about__team-item:nth-last-child(2) {
    grid-column: 3 span;
  }
}

.about__team-item-desc {
  dt {
    float: left;
    font-weight: 700;
    line-height: 120%;

    &::after {
      content: '\00a0';
    }
  }

  dd {
    margin-bottom: 6px;
    line-height: 120%;
  }
}

.about__team-features {
  @media (min-width: 992px) {
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
    padding: 32px;
  }
}

.about__team-features-item {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
  background-color: var(--color_bg--primary-light);
  padding: 16px;
  padding-right: 32px;
  text-wrap: balance;
}

.about__form button {
  max-width: 255px;
  margin-left: auto;
  display: block;
}

@media (max-width: 992px) {
  .about__info {
    grid-template-columns: 1fr;
  }

  .about__features-list {
    grid-template-columns: repeat(2, 1fr);

    .top-category__item {
      grid-column: 1 span;
    }

    .top-category__item:nth-last-child(1) {
      grid-column: 2 span;
    }

    .top-category__item:nth-last-child(2) {
      grid-column: 1 span;
    }
  }

  .about__team-list {
    grid-template-columns: repeat(2, 1fr);

    .about__team-item {
      grid-column: 1 span;
    }

    .about__team-item:nth-last-child(1), .about__team-item:nth-last-child(2) {
      grid-column: 1 span;
    }
  }

  .about__team-features-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .about__history-item {
    &::after {
      height: 104%;
    }
  }
}

@media (max-width: 720px) {
  .about__features-list {
    grid-template-columns: 1fr;

    .top-category__item:nth-last-child(1) {
      grid-column: 1 span;
    }
  }

  .about__history-item {
    &::after {
      height: 97%;
    }

    dl {
      grid-template-columns: 1fr;

      dd {
        padding-left: 36px;
      }
    }
  }

  .about__team-list {
    grid-template-columns: 1fr;
  }

  .about__team-features-list {
    grid-template-columns: 1fr;
  }

  .about__form button {
    max-width: none;
  }
}
