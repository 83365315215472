.main-nav__profile {
  display: none;
}

.main-nav__login {
  display: flex;
  align-items: center;
}

.main-nav__profile-name {
  display: none;
}

.main-nav__phone, .main-nav__cart, .main-nav__registration {
  display: none;
}

.main-nav__menu-toggle {
  display: flex;
}

.main-nav__mobile-menu {
  position: absolute;
  top: 64px;
  left: -200%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--color_bg--primary-light);
  transition: 0.5s ease-in-out;
  height: 100vh;
  width: calc(100% - var(--scroll-width));
  box-shadow: 0 100px 0 100px var(--color_bg--primary-light);
}

.main-nav__mobile-menu--open {
  left: 0;
}

@media (min-width: 720px) {
  .main-nav__mobile-menu {
    width: 50%;
    box-shadow: none;
    margin: 0 0 0 auto;
  }
}

@media (min-width: 1200px) {
  .main-nav__profile {
    display: block;
  }

  .main-nav__menu-toggle {
    display: none;
  }

  .main-nav__mobile-menu {
    display: none;
  }

  .main-nav__phone, .main-nav__cart, .main-nav__registration {
    display: flex;
  }

  .main-nav__profile-name {
    display: block;
    max-width: 20ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .main-nav__menu-toggle {
    display: none;
  }
}
