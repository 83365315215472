.main-header__wrap {
  background-color: var(--color_bg--primary-light);
}

.main-header {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
}

@media (width <= 1200px) {
  .main-header--search-open {
    .main-header__logo {
      display: none;
    }

    .main-header__search {
      grid-column: 1 / -1;

      .field {
        background: none;
      }
    }

    .main-header__field-wrapper {
      display: block;
    }

    .main-header__search-open {
      display: none;
    }

    .main-header__main-nav {
      display: none;
    }

    .main-header__field-button--search {
      display: none;
    }

    .main-header__field-button--reset {
      display: flex;
    }
  }
}

.main-header__field-wrapper {
  display: none;
}

.main-header__field-button--reset {
  display: none;
}

.main-header__main-nav {
  order: -1;
}

.main-nav__registration {
  display: none;
}

.main-header__logo {
  display: flex;
  justify-content: center;
  color: var(--color_text--base);
  border-radius: var(--radius-base);
}

@media (width >= 1200px) {
  .main-header {
    grid-template-columns: min-content auto max-content;
    gap: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .main-header__field-wrapper {
    display: block;
  }

  .main-header__field {
    border-radius: var(--radius-half);
  }

  .main-header__field:focus {
    & ~ .main-header__field-button--search {
      display: none;
    }

    & ~ .main-header__field-button--reset {
      display: flex;
    }
  }

  .main-header__field:not(:placeholder-shown) {
    & ~ .main-header__field-button--search {
      display: none;
    }

    & ~ .main-header__field-button--reset {
      display: flex;
    }
  }

  .main-header__field-button--reset:active {
    display: flex;
  }

  .main-header__field-button {
    border-radius: 0 var(--radius-half) var(--radius-half) 0;
  }

  .main-header__search {
    margin-left: 40px;
    margin-right: 20px;
  }

  .main-header__search-open {
    display: none;
  }

  .main-header__field-button {
  }

  .main-header__main-nav {
    order: 2;
    display: flex;
    gap: 10px;
  }

  .main-nav__registration {
    display: flex;
  }

  .main-header__logo .icon {
    width: 156px;
    height: 40px;
  }
}
