:disabled {
  cursor: not-allowed !important;

  & ~ label {
    cursor: not-allowed !important;
  }
}

.input__wrapper {
  position: relative;
  width: 100%;
  container-type: inline-size;
}

.input__wrapper--toggle {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  gap: 4px 8px;
  align-items: center;
}

.input__wrapper--field {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
}

.input__description {
  cursor: pointer;
  line-height: 150%;
}

.input__description label {
  cursor: pointer;
}

.input__description:has(+ .field__wrapper > input:disabled) {
  cursor: default;
  color: var(--color--disabled);
}

input:disabled ~ .input__description {
  cursor: default;
  color: var(--color--disabled);
}

.input__hint {
  grid-column: 2 / 3;
}

input:disabled ~ .input__hint {
  color: var(--color--disabled-secondary);
}
