.toggle {
  position: absolute;
  pointer-events: none;
  opacity: 0;

  &:checked + label {
    background-color: var(--color--accent);

    &::after {
      left: calc(50% - 4px);
    }
  }

  & + label {
    height: 32px;
    width: 52px;
    display: block;
    cursor: pointer;
    background: var(--color_icon--tertiary);
    border-radius: var(--radius-half);
    position: relative;
  }

  &:hover + label {
    background-color: var(--color_line--primary-hover);
  }
  &:checked:hover + label {
    background-color: var(--color_bg--input-hover);
  }

  &:disabled + label {
    background-color: var(--color--disabled-secondary);
  }
  &:checked:disabled + label {
    background-color: var(--color--disabled);
  }

  &:focus-visible + label {
    outline: 2px solid var(--color--accent);
    outline-offset: 2px;
  }

  & + label::after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: var(--radius-half);
    background-color: var(--color--primary);
    top: 2px;
    left: 2px;
    transition: 0.1s ease;
  }
}