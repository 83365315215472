:root {
  /* DEVICE WIDTHS */
  --mobile-width: 576px;
  --tablet-width-m: 720px;
  --tablet-width-l: 992px;
  --desktop-width: 1200px;

  --tablet-width: var(--tablet-width-m);

  /* COLORS */
  --color--accent: #2A5AFC;
  --color--accent-secondary: #A1B7FE;
  --color--accent-tertiary: #EAEFFF;
  --color--accent-tertiary-hover: #D8E5EA;
  --color--accent-tertiary-active: #CDD9DE;
  --color--accent-hover: #4D869E;
  --color--accent-active: #288195;
  --color--primary: #ffffff;
  --color--primary-inverse: #000000;
  --color--disabled: #B3B3B3;
  --color--disabled-secondary: #CCCCCC;
  --color--error: #FF3D12;
  --color--warning: #E6B60A;
  --color--success: #6FCF0F;
  /* text */
  --color_text--base: #1A1E2B;
  --color_text--primary: #000000;
  --color_text--primary-inverse: #ffffff;
  --color_text--secondary: #242731;
  --color_text--secondary-dark: #1E222E;
  --color_text--secondary-light: #2E3A4C;
  --color_text--tertiary: #9BA3BE;
  --color_text--placeholder: #849EA4;
  /* background */
  --color_bg--primary: #F7F7F8;
  --color_bg--primary-light: #FFFFFF;
  --color_bg--primary-inverse: #1A1E2B;
  --color_bg--base: #2E323F;
  --color_bg--tertiary: #FFEDED;
  --color_bg--input-hover: #288195;
  /* line */
  --color_line--primary: #E2E3E6;
  --color_line--base: #2E323F;
  --color_line--primary-hover: #2A5AFC;
  --color_line--primary-active: #3088AD;
  /* icon */
  --color_icon--primary: #FBFBFB;
  --color_icon--secondary: #1A1E2B;
  --color_icon--tertiary: #9BA3BE;

  /* GRADIENTS */
  --gradient-accent: #5F84FE, #2A5BFC;
  --gradient-accent-dark: #536796, #1E222E;

  /*SHADOWS*/
  --shadow--xl: 0px 2px 4px rgba(0, 0, 0, 0.3);
  --shadow--l: 0px 4px 8px rgba(0, 0, 0, 0.24);
  --shadow--m: 0px 6px 16px rgba(0, 0, 0, 0.16);
  --shadow--s: 0px 12px 32px rgba(0, 0, 0, 0.08);
  --shadow--accent: 0px 6px 16px rgba(49, 161, 185, 0.65);
  --shadow--accent-inset: inset 0px 6px 16px rgba(49, 161, 185, 0.65);

  /* BORDER RADIUS */
  --radius-base: 4px;
  --radius-half: 99rem;

  /* BASE SPACER */
  --spacer: 4px;
}
