.pagination {
  grid-column: 1 / -1;
  background-color: var(--color_bg--primary-light);
  border-radius: 12px;
  border: 1px solid var(--color_line--primary);
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
}

.pagination__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination__item {
  display: flex;
}

.pagination__item .link {
  cursor: pointer;
}

.pagination__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: var(--color_bg--primary);
  }
}

.pagination__item--current a {
  background-color: var(--color--accent);
  color: var(--color_text--primary-inverse);

  &:hover {
    background-color: var(--color--accent);
  }
}

.pagination__item-ellipsis {
  display: none;

  & a:hover {
    background-color: var(--color_bg--primary-light);
  }
}

.pagination__item--nav {
  color: var(--color_text--tertiary);

  &:hover {
    color: var(--color--accent);

    & .icon {
      background-color: var(--color--accent-tertiary);
    }
  }

  & .icon {
    background-color: var(--color_bg--primary);
  }
}

.pagination--trim-start .pagination__item-ellipsis--start {
  display: block;
}

.pagination--trim-end .pagination__item-ellipsis--end {
  display: block;
}

@media (width >= 720px) {
  .pagination__item:nth-child(n):not(.pagination__item-ellipsis) {
    display: flex;
  }

  .pagination--trim-start .pagination__item-ellipsis--start {
    display: block;
  }

  .pagination--trim-end .pagination__item-ellipsis--end {
    display: block;
  }
}
