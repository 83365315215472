.link {
  color: inherit;
}

.link:active {
  opacity: 0.5;
}

.link_color--primary {
  color: var(--color--primary-inverse);

  &:hover {
    color: var(--color--accent);
  }
}

.link_color--accent {
  color: var(--color--accent);

  &:hover {
    color: var(--color--accent-hover);
  }
}

.link_color--primary-inverse {
  color: var(--color--primary);

  &:hover {
    color: var(--color--accent);
  }
}

.link_view--primary {
  text-decoration: none;
}

.link_view--underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.link_view--hover-underline:hover {
  text-decoration: underline;
}

.link_view--hover-opacity {
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.link_view--hover-accent {
  &:hover {
    color: var(--color--accent);
  }

  &:active {
    opacity: 0.6;
  }
}

.link:not([href]) {
  cursor: default;

  &:active {
    opacity: 1;
  }

  &:hover {
    text-decoration: none;
  }
}
