.article {
  margin-top: 32px;

  font-family: Roboto, Arial, sans-serif;
  color: #333;
}

.article__header {
  padding: 16px;
  border-radius: 32px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
  }
}

.article__date {
  padding: 18px 14px;
  border-radius: 16px;
  background-color: rgba(30, 31, 35, 0.68);
  color: #FFF;
  font-size: 16px;
  line-height: 15px;
  display: block;
  width: max-content;
  margin-bottom: 16px;
}

.article__title {
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(30, 31, 35, 0.68);

  color: #FFF;

  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.article__info {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;

  color: #79899D;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}

.article__info-col {
  display: flex;
  gap: 38px;
  align-items: center;
}

.article__info-time, .article__info-view, .article__info-copy {
  display: flex;
  align-items: center;
  gap: 10px;
}

.article__info-copy {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:hover .icon {
    color: var(--color--accent);
  }

  &:active {
    opacity: 0.6;
  }
}

.article__nav {
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: linear-gradient(180deg, #EBEBF5 0%, #EAE9FF 100%);
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 32px;
  position: relative;

  &::after {
    content: '';
    position: absolute;

    width: 370px;
    height: 94px;

    background-image: url('../../../public/img/logo.svg');
    background-size: cover;

    transform: rotate(-9deg);
    opacity: 0.03;

    top: 25px;
    right: 35px;
  }
}

.article__nav-item {
  padding-left: 20px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: #7B61FF;
    left: 0;
    top: 2.75px;
  }
}

.article__content h2, .article__content .h2 {
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 25px;
  margin-top: 30px;
}

.article__content h3, .article__content .h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 15px;
  margin-top: 20px;
  color: #333;
}

.article__content > p {
  color: #040404;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  margin-bottom: 10px;
}

.article__content strong {
  font-weight: 700;
  color: #333;
}

.article__content > img, .article__content p > img {
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: auto;

  margin-bottom: 64px;
  margin-top: 20px;

  aspect-ratio: 2 / 1;
}

.article__content figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin-bottom: 64px;

  picture {
    display: flex;
  }

  img {
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  figcaption {
    color: #5A5A5A;
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 27.2px;
  }
}

.article__content blockquote {
  padding: 66px 0;
  background-image: url("data:image/svg+xml,%3Csvg width='66' height='49' viewBox='0 0 66 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='65' height='48' transform='translate(0.5 0.560059)' fill='%23F7F7F8'/%3E%3Cpath d='M55.5 38.5601H35.25V22.8801L43.125 10.5601H53.25L48.75 21.7601H55.5V38.5601ZM30.75 38.5601H10.5V22.8801L18.375 10.5601H28.5L24 21.7601H30.75V38.5601Z' fill='%232A5AFC'/%3E%3C/svg%3E%0A"), url("data:image/svg+xml,%3Csvg width='66' height='49' viewBox='0 0 66 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='65' height='48' transform='translate(0.5 0.560059)' fill='%23F7F7F8'/%3E%3Cpath d='M10.5 10.5601L30.75 10.5601L30.75 26.2401L22.875 38.5601L12.75 38.5601L17.25 27.3601L10.5 27.3601L10.5 10.5601ZM35.25 10.5601L55.5 10.5601L55.5 26.2401L47.625 38.5601L37.5 38.5601L42 27.3601L35.25 27.3601L35.25 10.5601Z' fill='%232A5AFC'/%3E%3C/svg%3E%0A");

  background-repeat: no-repeat;

  background-position: top center, bottom center;

  margin-bottom: 64px;

  position: relative;

  &::after {
    width: 100%;
    height: 22px;
    border: 6px solid rgba(42, 90, 252, 0.1);
    border-bottom: none;
    z-index: -1;

    left: 0;
    top: 26px;

    content: '';
    position: absolute;
  }

  &::before {
    width: 100%;
    height: 22px;
    border: 6px solid rgba(42, 90, 252, 0.1);
    border-top: none;
    z-index: -1;

    left: 0;
    bottom: 26px;

    content: '';
    position: absolute;
  }

  text-align: center;
  font-size: 16px;
  font-style: italic;
  line-height: 150%;
}

.article__content .faq {
  border-radius: 6px;
  border: 1px solid #D5DBE0;

  color: #040404;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;

  margin-top: 32px;

  & ~ .faq {
    margin-top: 10px;
  }

  &:not(:has(+ .faq)) {
    margin-bottom: 64px;
  }

  &.open {
    > :first-child  {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    > :not(:first-child) {
      display: block;
    }
  }

  > :first-child {
    list-style: none;
    color: #333;

    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;

    position: relative;
    padding: 10px;
    padding-right: 44px;
    margin: 0;

    &::after {
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_3082_44360' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.560059' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3082_44360)'%3E%3Cpath d='M12.0192 15.9447L15.3077 12.6562L14.6192 11.9677L12.5192 14.0677V9.15621H11.5V14.0677L9.4 11.9677L8.71152 12.6562L12.0192 15.9447ZM12.0034 21.5601C10.7588 21.5601 9.58872 21.3239 8.4931 20.8516C7.39748 20.3792 6.44444 19.7382 5.63397 18.9285C4.82352 18.1188 4.18192 17.1667 3.70915 16.0721C3.23638 14.9775 3 13.8079 3 12.5634C3 11.3189 3.23616 10.1488 3.70848 9.05316C4.18081 7.95754 4.82183 7.0045 5.63153 6.19403C6.44123 5.38358 7.39337 4.74198 8.48795 4.26921C9.58255 3.79644 10.7521 3.56006 11.9966 3.56006C13.2412 3.56006 14.4113 3.79622 15.5069 4.26853C16.6025 4.74087 17.5556 5.38188 18.366 6.19158C19.1765 7.00128 19.8181 7.95343 20.2908 9.04801C20.7636 10.1426 21 11.3122 21 12.5567C21 13.8013 20.7638 14.9713 20.2915 16.067C19.8192 17.1626 19.1782 18.1156 18.3685 18.9261C17.5588 19.7365 16.6066 20.3781 15.512 20.8509C14.4174 21.3237 13.2479 21.5601 12.0034 21.5601ZM12 20.5601C14.2333 20.5601 16.125 19.7851 17.675 18.2351C19.225 16.6851 20 14.7934 20 12.5601C20 10.3267 19.225 8.43506 17.675 6.88506C16.125 5.33506 14.2333 4.56006 12 4.56006C9.76667 4.56006 7.875 5.33506 6.325 6.88506C4.775 8.43506 4 10.3267 4 12.5601C4 14.7934 4.775 16.6851 6.325 18.2351C7.875 19.7851 9.76667 20.5601 12 20.5601Z' fill='%232A5AFC'/%3E%3C/g%3E%3C/svg%3E%0A");
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      transition: 0.5s ease-in-out;
    }
  }

  > :not(:first-child) {
    display: none;
    margin-left: 10px;
    margin-right: 10px;
  }

  > :nth-child(2) {
    padding-top: 15px;
    border-top: 1px solid #D5DBE0;
  }

  > :last-child {
    margin-bottom: 10px;
  }
}

.article__more-wrap {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  justify-content: space-between;
  gap: 30px;
  overflow: scroll;

  margin-bottom: 64px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.article__more-card {
  border-radius: 20px;
  border: 1px solid #79899D;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

.article__more-card-inner {
  padding: 14px 15px 12px;

  h3 {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;

    margin-bottom: 8px;
  }

  time {
    color: #79899D;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.article__content ol {
  counter-reset: li;
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 64px;

  li {
    position: relative;
    padding-left: 25px;

    font-size: 16px;
    line-height: 170%;

    &::before {
      counter-increment: li;
      content: counter(li) '. ';
      color: #2A5AFC;
      font-weight: 700;

      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.article__content ul {
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 64px;

  li {
    position: relative;
    padding-left: 25px;

    font-size: 16px;
    line-height: 170%;

    &::before {
      content: '';
      background-color: #2A5AFC;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      font-weight: 700;

      position: absolute;
      left: 4px;
      top: 8px;
    }
  }
}

.article__content p a {
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
}

.article__content .blog-custom__table {
  width: 100%;
  overflow: auto;

  scrollbar-width: auto;
  scrollbar-color: #18181b #ffffff;

  margin-bottom: 32px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #EAB464;
    border-radius: 10px;
  }

  table {
    width: 100%;
    border-radius: 4px;
    border-style: hidden;
    outline: 1px solid #b8d6d6;
    outline-offset: -1px;
    overflow: hidden;
  }

  td, th {
    border: 1px solid #b8d6d6;
    border-bottom: none;
    padding: 12px;
  }

  tr td:nth-child(2) {
    width: 20%;
    min-width: 100px;
  }
}

.blog-custom__table {
  color: #212124;

  td, th {
    border: none;
  }

  tbody tr:nth-child(1n+1) td {
    border-bottom: 1px solid #c5d8de;
    background: #dbf0f7
  }

  tbody tr:nth-child(odd) td {
    border-bottom: 1px solid #b8d6d6;
    background: #cee
  }

  tbody tr:nth-child(3n+1) td {
    border-bottom: 1px solid #e4d4bc;
    background: #fdebd1
  }

  tbody tr:nth-child(4n+1) td {
    border-bottom: 1px solid #e6c8c7;
    background: #ffdedd
  }
}


.article__join, .article__subscribe {
  padding: 16px 60px;
  display: flex;
  justify-content: space-between;
  gap: 50px;

  margin-bottom: 64px;
  margin-top: 64px;

  border-radius: 4px;
  border: 1px solid #D5DBE0;

  background-color: #FAFAFA;

  background-image: url('../../../public/img/blog/join-decor.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.article__subscribe {
  background-image: url('../../../public/img/blog/join-decor.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.article__join-inner, .article__subscribe-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  b {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
  }

  button {
    text-transform: uppercase;
  }

  a {
    width: max-content;
  }
}

.article__subscribe-inner {
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 100px;
  }
}

@media (max-width: 992px) {
  .article__header {
    padding: 16px 0;
    border-radius: 15px;
    height: 420px;
    margin-bottom: 32px;
  }

  .article__date {
    border-radius: 0 16px 16px 0;
  }

  .article__title {
    border-radius: 0;
  }

  .article__info-col {
    flex-direction: column;
    gap: 8px;
  }

  .article__info-col--left {
    align-items: flex-start;
  }

  .article__info-col--right {
    align-items: flex-end;
  }

  .article__join, .article__subscribe {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-image: none;
    margin-bottom: 40px;
  }

  .article__join img {
    width: 215px;
    height: auto;
  }

  .article__subscribe img {
    width: 245px;
    height: auto;
  }

  .article__join-inner {
    align-items: center;
    gap: 10px;

    b {
      text-align: center;
      font-size: 18px;
    }
  }

  .article__subscribe-inner {
    flex-direction: column;
    gap: 30px;

    img {
      margin-right: 0;
      width: 62px;
      height: auto;
    }
  }

  .article__nav {
    margin-bottom: 64px;
  }

  .article > p {
    margin-bottom: 40px;
  }

  .article blockquote {
    margin-bottom: 16px;
  }

  .article figure {
    img {
      border-radius: 10px;
    }
  }
}

@media (max-width: 720px) {
  .article__header {
    padding: 0;
    overflow: visible;
    height: auto;
    margin-bottom: 40px;
  }

  .article__header img {
    position: relative;
    order: -1;
    margin-bottom: 8px;

    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    border-radius: 16px;
  }

  .article__date {
    padding: 0;
    background: none;
    color: #000;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
  }

  .article__title {
    padding: 0;
    background: none;

    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }

  .article__info {
    margin-bottom: 40px;
  }

  .article__nav {
    margin-bottom: 48px;

    &::after {
      display: none;
    }
  }

  .article > p {
    margin-bottom: 24px;
  }

  .article__join {
    margin-bottom: 24px;
  }

  .article figure {
    margin-bottom: 32px;

    img {
      border-radius: 4px;
    }
  }

  .article__content > img {
    aspect-ratio: 4 / 3;
  }

  .article__numbered-list, .article__unnumbered-list {
    margin-bottom: 32px;
  }
}
