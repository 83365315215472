.promo {
  background-color: var(--color_text--secondary-dark);
  color: var(--color_text--primary-inverse);
  position: relative;
  margin-bottom: 54px;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.promo__item picture {
  width: 100%;
  order: -1;
}

.promo__inner {
  display: grid;
  gap: 20px 70px;
}

.promo__item {
  display: none;
  gap: 20px;
  flex-direction: column;
}

@for $i from 1 through 3 {
  [data-promo-banner="#{$i}"] .promo__item--#{$i} {
    display: flex;
  }
}

.promo__slider-bullet-list {
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  z-index: 1;
}

.promo__slider-bullet-item button {
  border: none;
  padding: 0;
  width: 40px;
  height: 8px;
  border-radius: var(--radius-half);
  background-color: var(--color_icon--tertiary);
  cursor: pointer;
  transition: 0.5s ease-in-out width;

  &:hover {
    background-color: var(--color--accent-tertiary);
    outline: 1px solid var(--color--accent);
  }
}

@for $i from 1 through 3 {
  [data-promo-banner="#{$i}"] [data-promo-bullet="#{$i}"] {
    width: 80px;
    background-image: linear-gradient(0deg, var(--color--accent), var(--color--accent));
    background-size: 0% 100%;
    background-repeat: no-repeat;
    animation: fill 4s ease-in-out forwards;
    cursor: default;

    &:hover {
      background-color: var(--color_icon--tertiary);
      outline: none;
    }
  }
}

@keyframes fill {
  0% {
    background-size: 0% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
}

.promo__desc {
  min-height: 63px;
}

@media (width >= 720px) {
  .promo__item picture {
    display: none;
  }

  @for $i from 1 through 3 {
    [data-promo-banner="#{$i}"] {
      background-image: url("../../../public/img/promo-banner-#{$i}-tablet@1x.webp");

      background-image: image-set(
        url("../../../public/img/promo-banner-#{$i}-tablet@2x.webp") type("image/webp") 2x,
        url("../../../public/img/promo-banner-#{$i}-tablet@1x.webp") type("image/webp") 1x,
        url("../../../public/img/promo-banner-#{$i}-tablet@2x.jpg") 2x,
        url("../../../public/img/promo-banner-#{$i}-tablet@1x.jpg") 1x
      );
    }
  }

  .promo--banner-2 {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 200px;
      background-image: linear-gradient(100deg, var(--gradient-accent-dark));
      clip-path: polygon(0 0, 100% 0, 0 100%);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 200px;
      background-image: linear-gradient(100deg, var(--gradient-accent-dark));
      clip-path: polygon(0 0, 0 100%, 100% 100%);
    }
  }

  .promo__content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .promo__content {
    z-index: 1;
  }

  .promo__inner {
    grid-template-columns: 330px auto;
  }
}

@media (width >= 1200px) {
  .promo {
    position: relative;
    margin-bottom: 66px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      height: 240px;
      background-image: linear-gradient(100deg, var(--gradient-accent-dark));
      clip-path: polygon(0 0, 100% 0, 0 100%);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 140px;
      height: 240px;
      background-image: linear-gradient(100deg, var(--gradient-accent-dark));
      clip-path: polygon(0 0, 0 100%, 100% 100%);
    }
  }

  .promo__content {
  }

  .promo__inner {
    grid-template-columns: 540px auto;
  }

  .promo__slider-bullet-list {
    padding: 18px;
  }
}

@media (width >= 1440px) {
  @for $i from 1 through 3 {
    .no-webp {
      [data-promo-banner="#{$i}"] {
        background-image: image-set(
                        "/public/img/promo-banner-#{$i}-desktop@1x.jpg" 1x,
                        "/public/img/promo-banner-#{$i}-desktop@2x.jpg" 2x
        );
      }
    }

    .webp {
      [data-promo-banner="#{$i}"] {
        background-image: image-set(
                        "/public/img/promo-banner-#{$i}-desktop@1x.webp" 1x,
                        "/public/img/promo-banner-#{$i}-desktop@2x.webp" 2x
        );
      }
    }
  }
}

@media (width <= 1200px) {
  .promo__button {
    width: 100%;
  }
}
