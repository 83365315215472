.section {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.section_space--m {
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 720px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: 1200px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section_space-top--m {
  padding-top: 40px;

  @media (min-width: 720px) {
    padding-top: 60px;
  }

  @media (min-width: 1200px) {
    padding-top: 100px;
  }
}

.section_space-bottom--m {
  padding-bottom: 40px;

  @media (min-width: 720px) {
    padding-bottom: 60px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 100px;
  }
}
