.buy-goods__item {
  background-color: var(--color_bg--primary-light);
  box-shadow: 100px 0 0 0 var(--color_bg--primary-light),
             -100px 0 0 0 var(--color_bg--primary-light);
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
  }
}

.buy-goods {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  box-shadow: 1000px 0 0 0 var(--color_bg--primary-light);
}

.buy-goods__left-col {
  width: 100%;
  order: -1;
}

.buy-goods__left-col > picture .buy-goods__gallery-item-image {
  height: 420px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: calc(4 * var(--spacer));
}

.buy-goods__gallery {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  }
  gap: 8px;
  position: relative;
}

.buy-goods__gallery-item img {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  cursor: pointer;
}

.buy-goods__slider-bullet-list {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.buy-goods__slider-bullet-item {
  flex-basis: 48px;
  flex-grow: 1;
  height: 6px;
  border-radius: var(--radius-half);
  background-color: var(--color_icon--tertiary);
  transition: 0.5s ease-in-out flex-basis;
}

@for $i from 1 through 3 {
  [data-buy-goods-slider="#{$i}"] [data-buy-goods-bullet="#{$i}"] {
    flex-basis: 88px;
    background-image: linear-gradient(0deg, var(--color--accent), var(--color--accent));
    background-size: 0 100%;
    background-repeat: no-repeat;
    animation: fill 4s ease-in-out forwards;
  }
}

.buy-goods__stat {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom:  1px solid var(--color_line--primary);
  border-top:  1px solid var(--color_line--primary);
  background-color: var(--color_bg--primary-light);
}

.buy-goods__stat-item {
  padding: 16px 8px;

  &:last-child {
    border-left:  1px solid var(--color_line--primary);
  }

  dl {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  dd {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.buy-goods__spoiler {
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid var(--color_line--primary);
  background-color: var(--color_bg--primary-light);

  &[open] .buy-goods__spoiler-title {
    .icon {
      transform: rotate(180deg);
    }
  }

  &:not([open]) .buy-goods__spoiler-title {
    margin-bottom: 0;
  }
}

.buy-goods__spoiler-title {
  list-style: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .icon {
    transition: 0.3s ease-in-out;
    transform: rotate(0deg);
  }
}

.buy-goods__close {
  order: -2;
  margin-left: auto;
  margin-bottom: 16px;
}

.buy-goods__compress-more {
  justify-content: flex-start;
  margin-top: 8px;
}

.buy-goods__compress-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.buy-goods__compress-more .icon {
  transition: 0.3s ease-in-out;
  transform: rotate(0deg);
}

.buy-goods__compress--open {
  .buy-goods__compress-text {
    display: block;
  }

  .buy-goods__compress-more .icon {
    transform: rotate(180deg);
  }
}

.buy-goods__info {
  display: flex;
  flex-direction: column;
  gap: 8px 0;

  > li:not(:last-child) {
    border-bottom: 1px solid var(--color_line--primary);
    padding-bottom: 8px;
  }

  dl {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  dt {
    color: var(--color_text--tertiary);
  }

  li:last-child dd {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.buy-goods__note {
  background-color: var(--color--accent-tertiary);
  color: var(--color--accent);
  border-radius: 8px;
  padding: 16px;
}

.buy-goods__purchase {
  background-color: var(--color_bg--primary-light);
  color: var(--color_text--primary-inverse);
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

.buy-goods__purchase-button {
  width: 100%;
  border-radius: 0;
}

.buy-goods__prices-old {
  display: none;
}

.buy-goods__prices-new {
  display: none;
}

.buy-goods__purchase-button-text {
  display: none;
}

@media (width >= 720px) {
  .buy-goods__item {
    box-shadow: none;
    background: none;
  }

  .buy-goods {
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
    box-shadow: none;
    width: 100%;
    border-radius: 16px;
    padding: 32px;
    display: grid;
    gap: 0 30px;
    grid-template-columns: 1fr 1fr;
  }

  .buy-goods__gallery {
    margin-bottom: 0;
    width: 100%;
    display: grid;
    overflow-x: hidden;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    position: relative;
  }

  .buy-goods__left-col > picture .buy-goods__gallery-item-image {
    height: 428px;
  }

  .buy-goods__gallery-item img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
  }

  .buy-goods__slider-bullet-list {
    display: none;
  }

  .buy-goods__close-text {
    display: none;
  }

  .buy-goods__close {
    position: absolute;
    right: 32px;
    top: 32px
  }

  .buy-goods__stat {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    border:  1px solid var(--color_line--primary);
    border-radius: 8px;
  }

  .buy-goods__stat-item {
    padding: 16px 8px;

    &:last-child {
      border-left: none;
      border-top:  1px solid var(--color_line--primary);
    }

    dl {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    dd {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .buy-goods__purchase {
    color: var(--color_text--primary-inverse);
    position: static;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    margin-bottom: 8px;
  }

  .buy-goods__purchase-button {
    width: max-content;
    border-radius: 8px;
  }

  .buy-goods__prices-old {
    display: block;
  }

  .buy-goods__prices-new {
    display: block;
  }

  .buy-goods__purchase-button-text {
    display: block;
  }

  .buy-goods__purchase-button-price {
    display: none;
  }
}

@media (width >= 1200px) {
  .buy-goods {
    width: 100%;
    grid-template-columns: 350px auto;
  }

  .buy-goods__left-col {
    grid-row: 1 / 4;
  }

  .buy-goods__left-col > picture .buy-goods__gallery-item-image {
    height: 495px;
    margin-bottom: calc(5 * var(--spacer));
  }

  .buy-goods__gallery {
    grid-template-columns: repeat(5, 1fr);
  }

  .buy-goods__right-col {
    grid-column: 2 / -1;
  }

  .buy-goods__info--two-col--even {
    > li:nth-last-child(2) {
      grid-column: 1 / -1;
    }
  }

  .buy-goods__info--two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 0;

    > li:not(:last-child) {
      padding-bottom: 16px;
    }

    > li:last-child {
      grid-column: 1 / -1;
    }

    > li:nth-child(2n + 2):not(:last-child) {
      padding-left: 30px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 20px;
        height: 40px;
        width: 1px;
        background-color: var(--color_line--primary);
      }
    }

    dd ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li:not(:last-child)::after {
        content: ',\A0';
      }
    }
  }

  .buy-goods__info--three-col {
    display: flex;
    flex-direction: row;
    gap: 32px;

    li:not(:last-child) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .buy-goods__spoiler-title .icon {
    display: none;
  }

  .buy-goods__purchase {
    margin-bottom: 0;
  }

  .buy-goods__note {
    display: none;
  }

  .buy-goods__compress-more {
    display: none;
  }

  .buy-goods__compress-text {
    display: block;
    -webkit-box-orient: inherit;
    -webkit-line-clamp: none;
    overflow: hidden;
  }
}
