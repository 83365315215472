.page-title--cheap {
  display: flex;
  gap: 8px 16px;
  flex-direction: column;
}

.page-title--cheap .cheap {
  order: -1;
}

@media (width >= 720px) {

}

@media (width >= 1200px) {
  .page-title--cheap {
    flex-direction: row;
    justify-content: space-between;
  }
  .page-title--cheap .cheap {
    order: 2;
  }
}
