

.goods-popup__wrap {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
}

.goods-popup__wrap--open {
  display: block;
}

.goods-popup {
  position: relative;
  background-color: var(--color_bg--primary);
  padding: 16px 16px 60px;
  box-shadow: 0 1000px 0 0 var(--color_bg--primary);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 44px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.goods-popup__left-col {
  width: 100%;
  order: -1;
}

.goods-popup__left-col > picture .goods-popup__gallery-item-image {
  height: 420px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: calc(4 * var(--spacer));
}

.goods-popup__gallery {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  }
  gap: 8px;
  position: relative;
}

.goods-popup__gallery-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;

    animation: 0.3s ease-in-out opacity;
  }
}

.goods-popup__gallery-item--active::after {
  display: none;
}

.goods-popup__gallery-item img {
  object-fit: cover;
  width: 72px;
  height: 72px;
  cursor: pointer;
}

.goods-popup__slider-bullet-list {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.goods-popup__slider-bullet-item {
  flex-basis: 48px;
  flex-grow: 1;
  height: 6px;
  border-radius: var(--radius-half);
  background-color: var(--color_icon--tertiary);
  transition: 0.5s ease-in-out flex-basis;
}

@for $i from 1 through 10 {
  [data-goods-popup-slider="#{$i}"] [data-goods-popup-bullet="#{$i}"] {
    flex-basis: 88px;
    background-image: linear-gradient(0deg, var(--color--accent), var(--color--accent));
    background-size: 0 100%;
    background-repeat: no-repeat;
    animation: fill 4s ease-in-out forwards;
  }
}

.goods-popup__stat {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom:  1px solid var(--color_line--primary);
  border-top:  1px solid var(--color_line--primary);
}

.goods-popup__stat-item {
  padding: 16px 8px;

  &:last-child {
    border-left:  1px solid var(--color_line--primary);
  }

  dl {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  dd {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.goods-popup__spoiler {
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid var(--color_line--primary);
  background-color: var(--color_bg--primary-light);

  &[open] .goods-popup__spoiler-title {
    .icon {
      transform: rotate(180deg);
    }
  }

  &:not([open]) .goods-popup__spoiler-title {
    margin-bottom: 0;
  }
}

.goods-popup__spoiler-title {
  list-style: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .icon {
    transition: 0.3s ease-in-out;
    transform: rotate(0deg);
  }
}

.goods-popup__close {
  order: -2;
  margin-left: auto;
  margin-bottom: 16px;
}

.goods-popup__compress-more {
  justify-content: flex-start;
  margin-top: 8px;
}

.goods-popup__compress-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.goods-popup__compress-more .icon {
  transition: 0.3s ease-in-out;
  transform: rotate(0deg);
}

.goods-popup__compress--open {
  .goods-popup__compress-text {
    display: block;
  }

  .goods-popup__compress-more .icon {
    transform: rotate(180deg);
  }
}

.goods-popup__info {
  display: flex;
  flex-direction: column;
  gap: 8px;

  dl {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid var(--color_line--primary);
  }

  dd {
    padding-bottom: 8px;
  }

  dt {
    color: var(--color_text--tertiary);
  }

  li:last-child dl {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.goods-popup__note {
  background-color: var(--color--accent-tertiary);
  color: var(--color--accent);
  border-radius: 8px;
  padding: 16px;
}

.goods-popup__purchase {
  background-color: var(--color_bg--primary-light);
  color: var(--color_text--primary-inverse);
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

.goods-popup__purchase-button {
  width: 100%;
  border-radius: 0;
}

.goods-popup__prices-old {
  display: none;
}

.goods-popup__prices-new {
  display: none;
}

.goods-popup__purchase-button-text {
  display: none;
}

.goods-popup__note-open {
  display: none;
}

@media (width >= 720px) {
  .goods-popup__wrap {
    padding: 40px;
    justify-content: center;
  }

  .goods-popup__wrap--open {
    display: flex;
  }

  .goods-popup {
    box-shadow: none;
    width: 690px;
    border-radius: 16px;
    padding: 32px;
    display: grid;
    gap: 0 20px;
    grid-template-columns: 303px 303px;
    height: calc(100vh - 80px);
    animation: show-goods-popup 0.5s ease-in-out;

    margin-right: var(--scroll-width);
  }

  .goods-popup .title {
    padding-right: 40px;
    order: -2;
    grid-column: 1 / -1;
  }

  .goods-popup__gallery {
    margin-bottom: 0;
    width: 100%;
    display: grid;
    overflow-x: hidden;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    position: relative;
  }

  .goods-popup__gallery-item img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
  }

  .goods-popup__slider-bullet-list {
    display: none;
  }

  .goods-popup__close-text {
    display: none;
  }

  .goods-popup__close {
    position: absolute;
    right: 32px;
    top: 32px
  }

  .goods-popup__stat {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    border:  1px solid var(--color_line--primary);
    border-radius: 8px;
  }

  .goods-popup__stat-item {
    padding: 16px 8px;

    &:last-child {
      border-left: none;
      border-top:  1px solid var(--color_line--primary);
    }

    dl {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    dd {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .goods-popup__purchase {
    color: var(--color_text--primary-inverse);
    position: static;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--color_line--primary);
    margin-bottom: 8px;
  }

  .goods-popup__purchase-button {
    width: max-content;
    border-radius: 8px;
  }

  .goods-popup__prices-old {
    display: block;
  }

  .goods-popup__prices-new {
    display: block;
  }

  .goods-popup__purchase-button-text {
    display: block;
  }

  .goods-popup__purchase-button-price {
    display: none;
  }
}

@media (width >= 1200px) {
  .goods-popup {
    width: 1110px;
    grid-template-columns: 507px 507px;
  }

  .goods-popup .title {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
  }

  .goods-popup__left-col {
    grid-row: 1 / 4;
  }

  .goods-popup__left-col > picture .goods-popup__gallery-item-image {
    height: 717px;
    margin-bottom: calc(5 * var(--spacer));
  }

  .goods-popup__gallery {
    grid-template-columns: repeat(5, 1fr);
  }

  .goods-popup__right-col {
    grid-column: 2 / -1;
    grid-row: 2 / 3;
    position: relative;
  }

  .goods-popup__stat {
    flex-direction: row;
    padding: 16px;
  }

  .goods-popup__stat-item {
    padding: 0;
    flex-grow: 1;
    display: flex;

    &:last-child {
      justify-content: flex-end;
      border-top: none;
      border-left:  1px solid var(--color_line--primary);;
    }
  }

  .goods-popup__spoiler-title {
    cursor: default;
  }

  .goods-popup__spoiler-title .icon {
    display: none;
  }

  .goods-popup__purchase {
    margin-bottom: 0;
  }

  .goods-popup__note-open {
    display: flex;
    margin-left: auto;
    padding-right: 35px;
    margin-right: -23px;
  }

  .goods-popup__note {
    display: none;
    position: absolute;
    border: 1px solid var(--color_line--primary);
    background-color: var(--color_bg--primary-light);
    color: var(--color_text--tertiary);
    width: max-content;
    bottom: 69px;
    right: 142px;
  }

  .goods-popup__compress-more {
    display: none;
  }

  .goods-popup__compress-more--visible {
    display: flex;
  }

  .goods-popup__compress-text {
    //display: block;
    //-webkit-box-orient: inherit;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .goods-popup__info {
    dl {
      flex-direction: row;
      justify-content: space-between;
    }

    li:last-child dl {
      border-bottom: 1px solid var(--color_line--primary);
    }

    dd ul {
      text-align: right;
    }
  }
}
